import React, { useState } from 'react'
import LineLink from 'components/LineLink'
import MarkdownWrap from 'components/MarkdownWrap'
import * as styles from './styles.module.scss'

const Component = ({ content, locale, isExcerptOnly, isStartOpen = false }) => {
  const [isOpen, setIsOpen] = useState(isStartOpen)
  const { excerpt, detail } = content || {}

  const toggleIsOpen = () => {
    setIsOpen(!isOpen)
  }
  const showClass = isOpen ? styles.show : ''

  const strings = {
    en: {
      close: 'Close',
      more: 'Product Specifications and Technical Parameters',
      moreShorter: 'Product Specs & Technical Parameters',
    },
    zh: {
      close: '关闭',
      more: '产品规格和技术参数',
      moreShorter: '产品规格和技术参数',
    },
  }

  return (
    <div>
      <MarkdownWrap body={excerpt} />
      {detail && !isExcerptOnly && (
        <>
          <div className={`${styles.detail} ${showClass}`}>
            <MarkdownWrap body={detail} />
          </div>
          <div
            className={`${styles.toggle} ${showClass}`}
            onClick={toggleIsOpen}
          >
            <LineLink
              className={styles.normal}
              content={{
                text: isOpen ? strings[locale].close : strings[locale].more,
              }}
            />
            <LineLink
              className={styles.shorter}
              content={{
                text: isOpen
                  ? strings[locale].close
                  : strings[locale].moreShorter,
              }}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default Component
