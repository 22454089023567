// extracted by mini-css-extract-plugin
export var arrowWrap = "styles-module--arrow-wrap--8hovo";
export var containerInner = "styles-module--container-inner--Cn8bG";
export var grid24 = "styles-module--grid-24--LHlLF";
export var isActive = "styles-module--is-active--kYc7N";
export var isPlaceholder = "styles-module--is-placeholder--bcYYO";
export var slideImageInner = "styles-module--slide-image-inner--Jyhcl";
export var slideText = "styles-module--slide-text--Fx--u";
export var swiperComponent = "styles-module--swiper-component--X9Jzt";
export var swiperImages = "styles-module--swiper-images--goh4P";
export var swiperText = "styles-module--swiper-text--GieGj";