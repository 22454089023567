import React, { useState } from 'react'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'
import TextExpand from 'components/TextExpand'
import SwiperCore, { Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'
import ArrowLong from 'components/ArrowLong'

SwiperCore.use([Autoplay, Navigation])

const Slideshow = ({ content, locale, isExcerptOnly, gridColumns = 12 }) => {
  const { items } = content
  const [swiper, setSwiper] = useState(null)
  const [textIndex, setTextIndex] = useState(0)
  const handleSlideChange = () => {
    if (swiper) {
      setTextIndex(swiper.realIndex)
    }
  }

  const gridClasses =
    gridColumns === 24 ? `grid-24 ${styles.grid24}` : `grid-12`

  return (
    <Inview className={`${gridClasses} ${styles.swiperComponent} fade-in up`}>
      <div className={styles.containerInner}>
        <SwiperImages
          items={items}
          setSwiper={setSwiper}
          handleSlideChange={handleSlideChange}
        />
        <SwiperText
          items={items}
          locale={locale}
          textIndex={textIndex}
          isExcerptOnly={isExcerptOnly}
        />
      </div>
    </Inview>
  )
}

const SwiperImages = ({ items, setSwiper, handleSlideChange }) => {
  let initialSlidesPerView = 1
  if (typeof window !== 'undefined' && window.innerWidth >= 1025) {
    initialSlidesPerView = 2
  }
  return (
    <>
      <Swiper
        onSwiper={setSwiper}
        onSlideChange={handleSlideChange}
        className={styles.swiperImages}
        navigation={{
          nextEl: '.swiper-button-next',
        }}
        loop={true}
        loopAdditionalSlides={items.length - initialSlidesPerView}
        speed={600}
        autoplay={{
          delay: 5000,
        }}
        spaceBetween={12}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          601: {
            slidesPerView: 1.5,
          },
          1025: {
            slidesPerView: 2,
          },
        }}
      >
        <div className={`swiper-button-next ${styles.arrowWrap}`}>
          <ArrowLong />
        </div>

        {items?.map((item, i) => (
          <SwiperSlide key={i}>
            {({ isActive }) => (
              <SlideImage content={item} isActive={isActive} />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}

const SwiperText = ({ items, locale, textIndex, isExcerptOnly }) => {
  let maxBodyLength = 0
  let placeholderSlide = items[0]
  items.forEach((item) => {
    let measure = item.excerpt?.length
    if (!isExcerptOnly && item.more) measure += item.more.length

    if (measure > maxBodyLength) {
      maxBodyLength = measure
      placeholderSlide = item
    }
  })

  return (
    <div className={styles.swiperText}>
      {items?.map((item, i) => {
        const isActive = i === textIndex
        return (
          <SlideText
            content={item}
            locale={locale}
            isActive={isActive}
            isExcerptOnly={isExcerptOnly}
            key={i}
          />
        )
      })}
      <SlideText
        content={placeholderSlide}
        locale={locale}
        isPlaceholder={true}
        isExcerptOnly={isExcerptOnly}
      />
    </div>
  )
}

const SlideImage = ({ content, isActive }) => {
  const { image, link } = content
  const isActiveClass = isActive ? styles.isActive : ''
  return (
    <LinkWrap
      to={link}
      className={`${styles.slideImageInner} ${isActiveClass}`}
    >
      <ImageWrap image={image} aspectRatio={3 / 2} />
    </LinkWrap>
  )
}

const SlideText = ({
  content,
  locale,
  isActive,
  isExcerptOnly,
  isPlaceholder,
}) => {
  const { title, subtitle, excerpt, more, link } = content
  const isActiveClass = isActive ? styles.isActive : ''
  const isPlaceholderClass = isPlaceholder ? styles.isPlaceholder : ''
  const textExpandContent = {
    excerpt,
    detail: more,
  }
  return (
    <LinkWrap
      to={link}
      className={`${styles.slideText} ${isActiveClass} ${isPlaceholderClass}`}
    >
      <h3>{title}</h3>
      <h5>{subtitle}</h5>
      <div className='p-smaller'>
        <TextExpand
          content={textExpandContent}
          locale={locale}
          isExcerptOnly={isExcerptOnly}
          isStartOpen={isPlaceholder}
        />
      </div>
    </LinkWrap>
  )
}

export default Slideshow
